<template>
  <div id="Content">
    <div class="content-inner container">
      <alert-modal ref="alert" />
      <confirmation-modal ref="confirmation" />
      <h1 class="refund-header">Refund Request</h1>
      <div class="subtitle">
        <p>{{ user.name }} has requested a refund.</p>
        <p class="green light-bold" v-if="refund.status === 'waiting'">
          Please either Approve or Deny their request upon review.
        </p>
      </div>
      <hr class="divider" />
      <loader site="buyers" :active="loading" />

      <div class="info-container" v-if="!loading">
        <div class="planner-notes">
          <label for="">Refund Reason: </label>
          <p>{{ refund.details }}</p>
        </div>

        <div class="guest-info" v-if="user && user.ticketsInfo">
          <ul>
            <li>
              <label for="">Name: </label>
              <span>{{ user.name }}</span>
            </li>
            <li>
              <label for="">Email: </label>
              <span>{{ user.email }}</span>
            </li>
            <li>
              <label for="">Tickets: </label>
              <span>{{ user.ticketsInfo.ticket_count }}</span>
            </li>
            <li>
              <label for="">Refund Amount: </label>
              <span>{{ user.ticketsInfo.total_amount }}</span>
              <p class="reminder">*Stripe will charge up to 4% for refunds, which will be reflected in your financials.</p>
            </li>
            <li>
              <label for="">Status: </label>
              <span>{{ refund.status.toUpperCase() }}</span>
            </li>
          </ul>
          <div v-if="refund.status === 'waiting'">
            <FormulateInput
              label="Reason:"
              type="textarea"
              v-model="formValues.reason"
            />
          </div>
        </div>
        <div class="buttons row">
          <div class="col full flex center" v-if="refund.status === 'waiting'">
            <diyobo-button
              txt="Approve"
              class="primary submit"
              @click="approve"
              type="approve"
            />
            <diyobo-button
              txt="Deny"
              class="primary submit"
              type="deny"
              @click="deny"
            />
          </div>
        </div>
      </div>

      <!-- <div class="main-cont" v-if="!loading && !submitted">
        <h2>
          Hey, Do you want to confirm the reservation for
          {{ eventName }}?
        </h2>
        <div class="row">
          <div class="col full flex center">
            <diyobo-button
              txt="Confirm"
              class="primary submit"
              @click="acceptGuest"
            />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import Loader from "@/components/Loader.vue";
import AlertModal from "@/components/modals/AlertModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

export default {
  components: {
    AlertModal,
    Loader,
    DiyoboButton,
    DiyoboInput,
    ConfirmationModal
  },

  data() {
    return {
      refund: {},
      user: {},
      loading: false,
      formValues: {
        reason: ""
      }
    };
  },
  methods: {
    approve() {
      // logic for approving refunds
      let refundId = this.$route.params.id;

      this.$refs.confirmation.open({
        title: "Are you sure?",
        msg: "Approve the refund request?",
        onConfirm: () => {
          this.$axios
            .post("/toteswap/approve-refund", {
              id: refundId,
              reason: this.formValues.reason
            })
            .then(({ data }) => {
              this.sent = true;
              this.$toast("Refund process started", { type: "success" });
              setTimeout(() => {
                location.reload();
              }, 3000);
            });
        }
      });
    },
    deny() {
      let refundId = this.$route.params.id;

      this.$refs.confirmation.open({
        title: "Are you sure?",
        msg: "Deny the refund request?",
        onConfirm: () => {
          this.$axios
            .post("/toteswap/deny-refund", {
              id: refundId,
              reason: this.formValues.reason
            })
            .then(({ data }) => {
              this.sent = true;
              this.$toast("Refund process denied", { type: "success" });
              setTimeout(() => {
                location.reload();
              }, 3000);
            });
        }
      });
    }
  },
  mounted() {
    if (!this.$store.state.user.isAuthenticated) {
      this.$router.push({
        path: "/login-register",
        query: { redirect: this.$route.fullPath }
      });
    } else {
      let refundId = this.$route.params.id;
      this.loading = true;
      this.$axios
        .post("/toteswap/get-refund", { id: refundId })
        .then(({ data }) => {
          this.user = data.user;
          this.refund = data.details;
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
#Content .content-inner.container {
  // text-align: center;
  .refund-header {
    text-align: center;
    color: var(--text);
  }

  max-width: 600px;

  .divider {
    margin: 2em 0;
  }
}

.info-container {
  text-align: left;

  .planner-notes {
    label {
      font-weight: bold;
    }

    p::first-letter {
      text-transform: capitalize;
    }
  }

  .buttons {
    button {
      margin-right: 20px;
    }
  }
}

.guest-info {
  background: var(--input-background);
  padding: 20px;
  box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 3px 0px #00000026;
  text-align: left;
  margin: 20px 0;
  border-radius: 10px;

  ul {
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 5px;
    }

    li label {
      font-weight: bold;
      color: #9a9a9a;
    }
  }
}
.reminder {
  margin: 0;
}
</style>
